import { MutableRefObject, forwardRef, Suspense } from 'react'
import dynamic from 'next/dynamic'

// Dynamic import of MarketingCard
const MarketingCard = dynamic(() => import('@/components/MarketingCard'), {
	loading: () => <div className="animate-pulse bg-gray-200 h-full w-full rounded-lg" />
})

import { DynamicBackground, HeaderContent } from 'ui'
import type { PageDocumentDataBodyMarketingCardsSlice } from 'types/types.generated'

const MarketingCardBlock = forwardRef(
	(props: PageDocumentDataBodyMarketingCardsSlice, ref: MutableRefObject<HTMLDivElement>) => {
		const { primary, items, globalData } = props || {}

		const {
			section_heading,
			heading_max_width,
			section_description,
			description_max_width,
			primary_cta_label,
			primary_cta_link,
			secondary_cta_label,
			secondary_cta_link,
			centered,
			background_color,
			background_height,
			background_squeegee_option,
			text_contrast_mode,
			marketing_cards_height
		} = primary

		return (
			<section
				className={`marketing-block section relative py-16 ${
					text_contrast_mode === 'light' ? 'text-white' : 'text-black-rt'
				} remove-space-if-in-rich-text`}
				ref={ref}
			>
				<DynamicBackground
					background_color={background_color}
					backgroundHeight={background_height}
					backgroundSqueegeeOption={background_squeegee_option}
				/>
				<div className="container">
					<HeaderContent
						title={section_heading}
						titleMaxWidth={heading_max_width}
						description={section_description}
						descriptionMaxWidth={description_max_width}
						primaryCta={{ label: primary_cta_label, link: primary_cta_link }}
						secondaryCta={{ label: secondary_cta_label, link: secondary_cta_link }}
						centered={centered}
						globalData={globalData}
					/>

					<div className="grid grid-cols-3 gap-4 md:grid-cols-12">
						{items.map((item, index) => (
							<Suspense
								key={index}
								fallback={<div className="animate-pulse bg-gray-200 h-full w-full rounded-lg" />}
							>
								<MarketingCard
									marketingData={{
										background_color: item?.marketing_card?.data?.background_color,
										background_image: item?.marketing_card?.data?.background_image,
										cta_label: item?.marketing_card?.data?.card_cta_label,
										cta_link: item?.marketing_card?.data?.card_cta_link,
										description: item?.marketing_card?.data?.card_description,
										title: item?.marketing_card?.data?.card_heading,
										label: item?.marketing_card?.data?.card_label,
										columns: item?.marketing_card?.data?.columns,
										content_alignment: item?.marketing_card?.data?.content_alignment,
										text_contrast_mode: item?.marketing_card?.data?.text_contrast_mode,
										tags: item?.marketing_card?.tags,
										height: marketing_cards_height
									}}
									globalData={globalData}
								/>
							</Suspense>
						))}
					</div>
				</div>
			</section>
		)
	}
)

MarketingCardBlock.displayName = 'MarketingCardBlock'

export default MarketingCardBlock
